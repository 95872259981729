
h3.title {
    float:left;
}
span.register-link {
    float:right;
}
@media (max-width: 575.98px) {
h3.title {
        float:none;
        margin-bottom: 15px;
}
span.register-link {
        float:none;
        margin-bottom: 15px;
        display:block;
}
}
.login-form {
    min-height:50vh;
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
}


